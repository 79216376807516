import React, { useState } from "react"
import { Collapse } from "react-collapse"

import * as styles from "./CollapsingCard.module.scss"
import placeholder from "../../images/placeholder.jpg"

const CollapsingCard = ({ content, isOpened, title, subtitle, featuredImage }) => {
  const [state, setState] = useState({
    isOpen: false,
  })

  const collapse = () => {
    setState({ isOpen: !state.isOpen })
  }

  let featuredImageImg = featuredImage
    ? (
      <img src={featuredImage.mediaDetails.sizes.filter(size => size.name === 'thumbnail')[0].sourceUrl} alt={featuredImage.altText} />
    )
    : < img src={placeholder} alt="" />

  return (
    <Collapse
      isOpened={isOpened}
      theme={{ collapse: styles.collapse, content: styles.collapseCard }}
    >
      <div
        role="button"
        tabIndex="0"
        className={content ? styles.CollapsingCard : styles.Card }
        onClick={collapse}
        onKeyDown={collapse}>
        <div className={styles.header}>
          <div className={styles.textContainer}>
            <h2 className={styles.title}>
              {title}
            </h2>
            <div className={styles.infos}>
              <div>
                <time className={styles.time}>{subtitle}</time>
              </div>
              {
                content && (
                  <div>
                    <span className={styles.more}>
                      {state.isOpen ? (
                        <>
                          -
                        </>
                      ) : (
                        <>
                          +
                        </>
                      )}
                    </span>
                  </div>
                )
              }
            </div>
          </div>
          <div className={styles.thumbnailContainer}>
            {featuredImageImg}
          </div>
        </div>
        {
          content && (
            <Collapse isOpened={state.isOpen} theme={{ collapse: styles.collapse, content: styles.collapseCardContent }}>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </Collapse>
          )
        }
      </div>
    </Collapse>
  )
}

export default CollapsingCard
