// extracted by mini-css-extract-plugin
export var collapse = "CollapsingCard-module--collapse---LpXo";
export var collapseCard = "CollapsingCard-module--collapseCard--F4kRP";
export var collapseCardContent = "CollapsingCard-module--collapseCardContent--i3UEx";
export var Card = "CollapsingCard-module--Card--CweWc";
export var CollapsingCard = "CollapsingCard-module--CollapsingCard--ivB39";
export var thumbnailContainer = "CollapsingCard-module--thumbnailContainer--OooL+";
export var header = "CollapsingCard-module--header--Sdi1F";
export var time = "CollapsingCard-module--time--o57hS";
export var title = "CollapsingCard-module--title--LPPrE";
export var textContainer = "CollapsingCard-module--textContainer--yUDob";
export var infos = "CollapsingCard-module--infos--p2Lj5";
export var more = "CollapsingCard-module--more--17dMZ";